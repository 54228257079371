import { Outlet } from "react-router-dom";
import { Header } from "../components/header/Header";
import { Footer } from "../components/footer/Footer";
import { NieveComponente } from "./../components/NieveComponente/NieveComponente";
import { MusicPlayer } from "../components/MusicChrismax/MusicChrismax";
const Layout = () => {
  return (
    <>
      {/* <NieveComponente /> */}
      {/* <MusicPlayer /> */}
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export { Layout };
